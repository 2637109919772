import React from "react";
import Header from "../../../../components/Header";

export default function SearchCompany() {
  return (
    <>
    <Header/>
      <div>
        {/* {data && <pre>{JSON.stringify(data, null, 2)}</pre>} */}

        <div className="homepage">
          <div className="container">
            <div className="title">
              <h2>CÔNG TY CỔ PHẦN XUẤT NHẬP KHẨU YẾN SÀO THÀNH DUNG</h2>
            </div>

            <div className="result">
              <ul>
                <li>
                  Tên quốc tế: THANH DUNG BIRD'S NEST IMPORT AND EXPORT JOINT
                  STOCK COMPANY
                </li>
                <li>Mã số thuế: 6001755043</li>
                <li>
                  Địa chỉ: Số 406 Giải Phóng, Thị Trấn Phước An, Huyện Krông
                  Pắc, Tỉnh Đắk Lắk, Việt Nam
                </li>
                <li>Người đại diện:</li>
                <li>Bà : PHẠM THỊ PHƯƠNG DUNG - Chủ tịch HĐQT</li>
                <li>Ông VŨ NGỌC HUY - Tổng giám đốc</li>
                <li>Điện thoại: 0976336677 - 0798508555</li>
                <li>Website: https://yensaocaocapthanhdung.com.vn/</li>
                <button>
                  <a href="/" rel="noopener noreferrer">
                    Quay về
                  </a>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
