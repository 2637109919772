import React, { useEffect, useState } from "react";

export default function SearchHouse({ inputValue }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://traceability.yensaocaocapthanhdung.com.vn/api/house/${inputValue}`
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data from ComponentB:", error);
      }
    };

    if (inputValue) {
      fetchData();
    }
  }, [inputValue]);

  console.log(data);

  return (
    <div>
      
      <div className="homepage">
        <div className="container">
          <div className="title">
            <h2>Thông tin nhà yến</h2>
          </div>

          <div className="result">
            {" "}
            {data && Object.keys(data).length > 0 ? (
              <ul>
                <li>Chủ nhà yến: {data.nameHouse}</li>
                <li>Mã nhà yến: {data.codeHouse}</li>
                <li>CCCD: {data.idcard}</li>
                <li>Địa chỉ: {data.addrHouse}</li>
                <li>Diện tích: {data.acreageHouse} m2</li>
                {/* <li>Vị trí: {data.locations}</li> */}
                {/* <li>
                  Vị trí:{" "}
                  <Link to={`https://www.google.com/maps/place/${data.locations}`}>
                    {data.locations}
                  </Link>
                </li> */}
                <button>
                  <a href="/" rel="noopener noreferrer">
                    Quay về
                  </a>
                </button>
              </ul>
            ) : (
              <>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    color: "red",
                  }}
                >
                  Không tìm thấy lô hàng
                </p>
                {/* <button>
                  <a href="/" rel="noopener noreferrer">
                    Quay về
                  </a>
                </button> */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
