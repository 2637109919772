import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/dist";
import Header from "../Header";


export default function DetailsHouse() {
  const { houseCode } = useParams();
  const [detailsHouse, setDetailsHouse] = useState("");

  useEffect(() => {
    axios
      .get(
        `https://traceability.yensaocaocapthanhdung.com.vn/api/house/${houseCode}`
      )
      .then((response) => setDetailsHouse(response.data))
      .catch((error) => console.error(error));
  }, []);

  console.log(detailsHouse);
  return (
    <>
      <Header />
      <div className="homepage">
        <div className="container">
          <div className="title">
            <h2>Thông tin nhà yến</h2>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <ul>
              <li>Mã nhà yến: {detailsHouse.codeHouse}</li>
              <li>Chủ nhà: {detailsHouse.nameHouse}</li>
              <li>CCCD: {detailsHouse.idcard}</li>
              <li>Địa chỉ: {detailsHouse.addrHouse}</li>
              <li>Diện tích: {detailsHouse.acreageHouse}</li>
              <button>
                <a href="/" rel="noopener noreferrer">
                  Quay về
                </a>
              </button>
             
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
