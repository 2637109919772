import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../Header";

export default function Details() {
  const { productionLot } = useParams();
  const [detailsLot, setDetailsLot] = useState("");

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
  
    // Format with leading zeros if needed
    const formattedDate = `${day}/${month}/${year}`;
  
    return formattedDate;
  }

  useEffect(() => {
    axios
      .get(
        `https://traceability.yensaocaocapthanhdung.com.vn/api/trace/${productionLot}`
      )
      .then((response) => setDetailsLot(response.data))
      .catch((error) => console.error(error));
  }, []);

  console.log(detailsLot);
  return (
    <>
      <Header />
      <div className="homepage">
        <div className="container">
          <div className="title">
            <h2>Thông tin lô hàng số: {productionLot}</h2>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <ul>
              <li>Tên sản phẩm: {detailsLot.name}</li>
              {/* <li>
                House code:{" "}
                <Link to={`/details-house/${detailsLot.houseCode}`}>
                  {detailsLot.houseCode}
                </Link>
              </li> */}
              <li>Mã nhà yến:{detailsLot.houseCode}</li>
              <li>Phương thức truy xuất: {detailsLot.recordRetrieval}</li>
              <li>Xuất sứ: {detailsLot.exportingCountry}</li>
              <li>Ngày sản xuất: {formatDate(detailsLot.productionDate)}</li>
              <li>Hạn sử dụng: {detailsLot.expiryDate} năm</li>
              <li>Khối lượng(gram): {detailsLot.weight} gram</li>
              <li>Bảo quản: {detailsLot.storage}</li>
             
              <button>
                <a href="/" rel="noopener noreferrer">
                  Quay về
                </a>
              </button>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
