import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DetailsHouse from './components/DetailsHouse'
import Details from './components/DetailsLot'
import Home from './pages/Home'
import SearchCompany from './pages/Home/components/searchCompany'

export default function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        {/* <Route path='/details-lot/:productionLot' element={<SearchLot/>}/>
        <Route path='/details-house/:houseCode' element={<SearchHouse/>}/> */}
        <Route path='/details-lot/:productionLot' element={<Details/>}/>
        <Route path='/details-house/:houseCode' element={<DetailsHouse/>}/>
        <Route path='/company' element={<SearchCompany/>}/>
      </Routes>
    </BrowserRouter>
    </>
  )
}
