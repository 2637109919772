import React from "react";
import "./index.scss";

export default function Header() {
  return (
    <header id="header" className="header has-sticky sticky-jump" style={{}}>
      <div className="header-wrapper">
        <div id="top-bar" className="header-top hide-for-sticky nav-dark">
          <div className="flex-row container">
            <div className="flex-col hide-for-medium flex-left">
              <ul className="nav nav-left medium-nav-center nav-small  nav-divided">
                <li className="html custom html_topbar_left">
                  <strong className="uppercase">
                    chuyên gia công yến theo yêu cầu, xuất khẩu yến sang các
                    nước
                  </strong>
                </li>{" "}
              </ul>
            </div>
            <div className="flex-col hide-for-medium flex-center">
              <ul className="nav nav-center nav-small  nav-divided"></ul>
            </div>
            <div className="flex-col hide-for-medium flex-right">
              <ul className="nav top-bar-nav nav-right nav-small  nav-divided">
                <li className="header-contact-wrapper">
                  <ul
                    id="header-contact"
                    className="nav nav-divided nav-uppercase header-contact"
                  >
                    <li className="">
                      <a
                        href="mailto:yensaocaocapthanhdung@gmail.com"
                        className="tooltip tooltipstered"
                      >
                        <i className="icon-envelop" style={{ fontSize: 16 }} />{" "}
                        <span>Contact </span>
                      </a>
                    </li>
                    <li className="">
                      <a className="tooltip" title="08:00 - 17:00 ">
                        <i className="icon-clock" style={{ fontSize: 16 }} />{" "}
                        <span>08:00 - 17:00</span>
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="tel:097 6336677"
                        className="tooltip"
                        title="097 6336677"
                      >
                        <i className="icon-phone" style={{ fontSize: 16 }} />{" "}
                        <span>097 6336677</span>
                      </a>
                    </li>
                  </ul>
                </li>{" "}
              </ul>
            </div>
            <div className="flex-col show-for-medium flex-grow">
              <ul className="nav nav-center nav-small mobile-nav  nav-divided">
                <li className="html custom html_topbar_left">
                  <strong className="uppercase">
                    chuyên gia công yến theo yêu cầu, xuất khẩu yến sang các
                    nước
                  </strong>
                </li>{" "}
              </ul>
            </div>
          </div>
        </div>
        <div id="masthead" className="header-main">
          <div
            className="header-inner flex-row container logo-left medium-logo-center"
            role="navigation"
          >
            {/* Logo */}
            <div id="logo" className="flex-col logo">
              {/* Header logo */}
              <a
                href="https://yensaocaocapthanhdung.com.vn/"
                title="YẾN SÀO CAO CẤP THANH DUNG"
                rel="home"
              >
                <img
                  width={142}
                  height={137}
                  src="https://yensaocaocapthanhdung.com.vn/wp-content/uploads/2023/06/LOGO_THANH_DUNG_NEW.png"
                  data-src="https://yensaocaocapthanhdung.com.vn/wp-content/uploads/2023/06/LOGO_THANH_DUNG_NEW.png"
                  className="header_logo header-logo lazy loaded"
                  alt="YẾN SÀO CAO CẤP THANH DUNG"
                  data-was-processed="true"
                />
                <img
                  width={142}
                  height={137}
                  src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20142%20137'%3E%3C/svg%3E"
                  data-src="https://yensaocaocapthanhdung.com.vn/wp-content/uploads/2023/06/LOGO_THANH_DUNG_NEW.png"
                  className="header-logo-dark lazy"
                  alt="YẾN SÀO CAO CẤP THANH DUNG"
                />
              </a>
            </div>
            {/* Mobile Left Elements */}
            <div className="flex-col show-for-medium flex-left">
              <ul className="mobile-nav nav nav-left ">
                <li className="nav-icon has-icon">
                  <a
                    href="#"
                    data-open="#main-menu"
                    data-pos="left"
                    data-bg="main-menu-overlay"
                    data-color=""
                    className="is-small"
                    aria-label="Menu"
                    aria-controls="main-menu"
                    aria-expanded="false"
                  >
                    <i className="icon-menu" />
                  </a>
                </li>{" "}
              </ul>
            </div>
            {/* Left Elements */}
            <div
              className="flex-col hide-for-medium flex-left
      flex-grow"
            >
              <ul className="header-nav header-nav-main nav nav-left  nav-uppercase"></ul>
            </div>
            {/* Right Elements */}
            <div className="flex-col hide-for-medium flex-right">
              <ul className="header-nav header-nav-main nav nav-right  nav-uppercase">
                <li
                  id="menu-item-51"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-51 active menu-item-design-default"
                >
                  <a
                    href="https://yensaocaocapthanhdung.com.vn/#gioithieu"
                    aria-current="page"
                    className="nav-top-link"
                  >
                    VỀ CHÚNG TÔI
                  </a>
                </li>
                <li
                  id="menu-item-52"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-52 active menu-item-design-default"
                >
                  <a
                    href="https://yensaocaocapthanhdung.com.vn/#giacong"
                    aria-current="page"
                    className="nav-top-link"
                  >
                    DỊCH VỤ GIA CÔNG
                  </a>
                </li>
                <li
                  id="menu-item-53"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-53 active menu-item-design-default"
                >
                  <a
                    href="https://yensaocaocapthanhdung.com.vn/#chungnhan"
                    aria-current="page"
                    className="nav-top-link"
                  >
                    CHỨNG NHẬN
                  </a>
                </li>
                <li
                  id="menu-item-149"
                  className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-149 menu-item-design-default"
                >
                  <a
                    href="https://yensaocaocapthanhdung.com.vn/category/tin-tuc/"
                    className="nav-top-link"
                  >
                    TIN TỨC
                  </a>
                </li>
                <li
                  id="menu-item-117"
                  className="menu-item menu-item-type-ecwid_menu_item menu-item-object-ecwid-store-with-categories menu-item-117 menu-item-design-default"
                >
                  <a
                    href="https://yensaocaocapthanhdung.com.vn/ma-xuat-khau/"
                    className="nav-top-link"
                  >
                    MÃ XUẤT KHẨU
                  </a>
                </li>
                <li
                  id="menu-item-60"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-60 menu-item-design-default"
                >
                  <a href="https://zalo.me/0976336677" className="nav-top-link">
                    LIÊN HỆ
                  </a>
                </li>
              </ul>
            </div>
            {/* Mobile Right Elements */}
            <div className="flex-col show-for-medium flex-right">
              <ul className="mobile-nav nav nav-right "></ul>
            </div>
          </div>
        </div>
        <div className="header-bg-container fill">
          <div className="header-bg-image fill" />
          <div className="header-bg-color fill" />
        </div>{" "}
      </div>
    </header>
  );
}
