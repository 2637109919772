import React, { useState } from "react";
import Header from "../../components/Header";
import SearchHouse from "./components/searchHouse";
import SearchLot from "./components/searchLot";
import "./index.scss";
import SearchCompany from "./components/searchCompany";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [inputValue, setInputValue] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const nav = useNavigate()
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setSearchPerformed(false);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearchPerformed(true);
  };

  return (
    <>
      <Header />
      <div className="homepage">
        <div className="container">
          <h2>Nhập số lô sản xuất hoặc mã nhà yến để tra cứu</h2>
          <form onSubmit={handleSearchClick}>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
            />
            <button type="submit">Tra cứu</button>
          </form>
          <br />
          {searchPerformed &&
            (/^6001755043$/.test(inputValue) ? (
              nav('/company')
            ) : /^\d+-\w+$/.test(inputValue) ? (
              <SearchHouse inputValue={inputValue} />
            ) : /^\d+$/.test(inputValue) ? (
              <SearchLot inputValue={inputValue} />
            ) : null)}
        </div>
      </div>
    </>
  );
}
