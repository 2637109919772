import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header";

export default function SearchLot({ inputValue }) {
  const [data, setData] = useState(null);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();

    // Format with leading zeros if needed
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://traceability.yensaocaocapthanhdung.com.vn/api/trace/${inputValue}`
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data from ComponentA:", error);
      }
    };

    if (inputValue) {
      fetchData();
    }
  }, [inputValue]);
  console.log(data);
  return (
    <div>
      
      <div className="homepage">
        <div className="container">
          <div className="title">
            <h2>Thông tin lô sản xuất</h2>
          </div>
          <div className="result">
            {" "}
            {data && Object.keys(data).length > 0 ? (
              <ul>
                <li>Tên sản phẩm: {data.name}</li>
                <li>Số lô: {data.productionLot}</li>
                {/* <li>
                  Mã nhà yến:{" "}
                  <Link to={`/details-house/${data.houseCode}`}>
                    {data.houseCode}
                  </Link>
                </li> */}
                <li>Mã nhà yến: {data.houseCode}</li>
                <li>Phương pháp ghi dãn: {data.recordRetrieval}</li>
                <li>Quốc gia xuất khẩu: {data.exportingCountry}</li>
                <li>Ngày sản xuất: {formatDate(data.productionDate)} </li>
                <li>Hạn sử dụng: {data.expiryDate} năm</li>
                <li>Khối lượng (gram): {data.weight} gram</li>
                <li>
                  Production: CTY CP XNK YẾN SÀO THÀNH DUNG
                  {/* <Link to={`/details-house/${data.houseCode}`}>
                    CTY CP XNK YẾN SÀO THÀNH DUNG
                  </Link> */}
                </li>
                <li>Điều kiện bảo quản: {data.storage}</li>
                <button>
                  <a href="/" rel="noopener noreferrer">
                    Quay về
                  </a>
                </button>
              </ul>
            ) : (
              <>
                {" "}
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    color: "red",
                  }}
                >
                  Không tìm thấy lô sản xuất
                </p>
                {/* <button>
                  <a href="/" rel="noopener noreferrer">
                    Quay về
                  </a>
                </button> */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
